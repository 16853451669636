<template>
    <section class="section faq-section" id="faq">
        <div class="container">
            <p class="title">{{ t('meclee-ui.landings.main.faq.title') }}</p>
            <Faq :faqContent="faqPsychologistContent" />
        </div>
    </section>
</template>
<script setup>
import Faq from "../Faq.vue"
import { useLocalePath } from "#i18n";
const localePath = useLocalePath();
const { t } = useI18n();

const props = defineProps({
    faqPsychologistContent: Array
})
</script>
